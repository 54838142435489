import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { theme } from 'constants/theme'

const AtomItem = styled(NavLink)`
  margin: 5px;
  border: 1px solid;
  border-color: ${theme.tabs.atoms.border};
  background-color: ${theme.tabs.atoms.background};
  color: ${theme.tabs.atoms.text()};
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  
  &:hover {
    color: #093A3E;
  }

  &.active {
    color: var(--color-text-on-secondary);
    background-color: var(--color-button-secondary);
    border: 1px solid;
    border-color: var(--color-button-secondary);
  }

  &.active path {
    stroke: ${theme.tabs.atoms.activeDeleteIcon};
  }
`

export { AtomItem }
