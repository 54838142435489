import React, {useEffect, useState} from 'react'
import Heading from 'uiKit/texts/Heading'
import Paragraph from 'uiKit/texts/Paragraph'
import Switch from 'uiKit/Switch'

import { toggleShutDown, toggleIsShutDown } from '../../../api/settings'


import * as S from './ShutDownBotBlock.styles'

export const ShutDownBotBlock = ({ activeBot }) => {
  const [shutDownEnabled, setShutDownEnabled] = useState(false)
  const handleToggleShutDown = async (e) => {
      e.persist()
    toggleShutDown(activeBot.id, e.target.checked)
      setShutDownEnabled(e.target.checked)
  }
  const handleIsShutDown = async () => {
    const newShutDownStatus = await toggleIsShutDown(activeBot.id)
    setShutDownEnabled(newShutDownStatus)
  }
  useEffect(() => {
    handleIsShutDown()
  }, [activeBot.id])
  return (
    <S.ShutDownBotBlock>
      <Heading>Shut down bot</Heading>
      <Paragraph>
          Shut down the widget on client's site.
      </Paragraph>
      <Switch
        label="Shut down"
        checked={shutDownEnabled}
        onChange={handleToggleShutDown}
      />
    </S.ShutDownBotBlock>
  )
}
