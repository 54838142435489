import React from 'react'

export const EditIcon = (props): JSX.Element => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M15.8321 2.64297C15.632 2.64297 15.4338 2.68238 15.249 2.75895C15.0641 2.83553 14.8961 2.94776 14.7546 3.08925L3.66112 14.1828L2.85307 17.1456L5.81595 16.3376L16.9095 5.24407C17.0509 5.10258 17.1632 4.93461 17.2398 4.74975C17.3163 4.56489 17.3557 4.36675 17.3557 4.16666C17.3557 3.96656 17.3163 3.76843 17.2398 3.58357C17.1632 3.39871 17.0509 3.23074 16.9095 3.08925C16.768 2.94776 16.6 2.83553 16.4151 2.75895C16.2303 2.68238 16.0321 2.64297 15.8321 2.64297ZM14.6112 1.21915C14.9982 1.05882 15.4131 0.976303 15.8321 0.976303C16.251 0.976303 16.6659 1.05882 17.0529 1.21915C17.44 1.37948 17.7917 1.61448 18.088 1.91074C18.3842 2.20699 18.6192 2.55869 18.7796 2.94576C18.9399 3.33284 19.0224 3.7477 19.0224 4.16666C19.0224 4.58562 18.9399 5.00048 18.7796 5.38756C18.6192 5.77463 18.3842 6.12633 18.088 6.42258L6.83797 17.6726C6.73543 17.7751 6.60789 17.8491 6.46798 17.8873L1.88465 19.1373C1.59614 19.216 1.28759 19.134 1.07613 18.9226C0.86467 18.7111 0.782729 18.4026 0.861414 18.1141L2.11141 13.5307C2.14957 13.3908 2.22358 13.2633 2.32613 13.1607L13.5761 1.91074C13.8724 1.61448 14.2241 1.37948 14.6112 1.21915Z"
        fill={props.color || 'var(--color-oxford-blue)'}
        fillOpacity="0.7"
      />
    </svg>
  )
}
