import React, {useContext, useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {DateControlContext} from '../../../../contexts/DateControlContext'
import {
  getUserStatisticsCount
} from '../../api/customCharts'
import {CustomDataChart} from './common/CustomDataChart'

interface UsersDataChartsProps {
  userStatisticsData: number
  finishedUserStatisticsData: number
  cartAddsInfluencedData: number
  botId: number
}

const UsersDataCharts: React.FC<UsersDataChartsProps> = ({
  userStatisticsData,
  finishedUserStatisticsData,
  cartAddsInfluencedData,
  botId
}) => {

  const [loading, setLoading] = useState(false)
  const {startDate, endDate} = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    getUserStatisticsCount(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  return <>
    <CustomDataChart
      data={userStatisticsData}
      title={'Users'}
      loading={loading}
      fractionDigits={0}
    />
    <CustomDataChart
      data={finishedUserStatisticsData}
      title={'Users finished'}
      loading={loading}
      fractionDigits={0}
    />
    <CustomDataChart
      data={cartAddsInfluencedData}
      title={'Cart Adds Influenced'}
      loading={loading}
      fractionDigits={0}
    />
  </>
}

const mapStateToProps = (state: {
  userStatisticsData: number,
  finishedUserStatisticsData: number,
  cartAddsInfluencedData: number,
  activeBot: any
}) => ({
  userStatisticsData: state.userStatisticsData,
  finishedUserStatisticsData: state.finishedUserStatisticsData,
  cartAddsInfluencedData: state.cartAddsInfluencedData,
  botId: state.activeBot?.id,
})

export default withRouter(connect(mapStateToProps)(UsersDataCharts))
