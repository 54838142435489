import React from 'react'
import { CSVLink } from 'react-csv'

import styles from './styles.module.scss'

interface Props {
  csvOnDownload?: (a: any) => any[]
  csvData?: any
  csvHeaders?: any
  csvFilename?: string
}

const DownloadButton: React.FC<Props> = ({ csvData, csvOnDownload, csvFilename, csvHeaders }) => {
  return (
    <CSVLink
      className={styles.button}
      data={csvOnDownload(csvData)}
      filename={`${csvFilename}.csv`}
      headers={csvHeaders}
      style={{ textDecoration: 'none' }}>
      Download
    </CSVLink>
  )
}

export default DownloadButton
