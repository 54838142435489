import React, {useContext, useEffect, useMemo, useState} from 'react'
import {DateControlContext} from '../../../../contexts/DateControlContext'
import {CustomDonutChart} from './common/CustomDonutChart'
import {getProductTypesDonutChatData} from '../../api/customCharts'
import {PieChartDTO} from '../../model/customChartModels'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

interface ProductTypesDonutChartProps {
  botId: number
  productTypesChartData?: PieChartDTO[]
}

const ProductTypesDonutChart: React.FC<ProductTypesDonutChartProps> = ({botId, productTypesChartData}) => {

  const [loading, setLoading] = useState(false)
  const {startDate, endDate} = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    getProductTypesDonutChatData(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  const data = useMemo(() => productTypesChartData && productTypesChartData.map(cd => {
    return [cd.type, cd.count]
  }), [productTypesChartData])

  return <CustomDonutChart
    title={'Product Type'}
    loading={loading}
    fileName={'product_type'}
    chartData={data}
  />
}

const mapStateToProps = (state) => ({
  productTypesChartData: state.productTypesChartData,
  botId: state.activeBot?.id,
})

export default withRouter(connect(mapStateToProps)(ProductTypesDonutChart))
