import React, { useState, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Button,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
} from '@material-ui/core'

import { access, ROLE } from '../../../../security'

import { DownArrowIcon } from '../../../../uiKit/icons/Icons'

import { getAccountSettings, updateAccountSettings } from '../../api/account'
import { loadBotAccessors } from '../../../settings/api/settings'
import {logout as sendLogout} from '../../../authorization/api/admin'
import {PrimaryColorSwitch} from '../../../../uiKit/Switch'

const imgContainerStyles = {
  position: 'relative',
}

const statusStyles = {
  position: 'absolute',
  boxSizing: 'border-box',
  width: 10,
  height: 10,
  border: '2px solid var(--color-white)',
  borderRadius: '50%',
  bottom: 0,
}

const baseImage = '/images/platform/default-user-icon.svg'

export const UserMenu = props => {
  const [open, setOpen] = useState(false)
  const [adminId, setAdminId] = useState(props.adminUser && props.adminUser.id)
  const anchorRef = useRef(null)
  const getImage = props?.accountSettings?.imageUrl || baseImage
  const activeColor = props?.accountSettings?.active ?
    'var(--color-malachite-green)' : 'var(--color-spun-pearl-grey)'
  const isLoader = props?.accountSettings

  useEffect(() => {
    if (!props.adminUser) {
      access.loadRole()
      return
    }

    if (!props.accountSettings || props.accountSettings.length === 0) {
      getAccountSettings(props.adminUser.id)
    }
  }, [])

  useEffect(() => {
    if (adminId === (props.adminUser && props.adminUser.id)) {
      return
    }

    if (props.adminUser && props.adminUser.id) {
      getAccountSettings(props.adminUser.id)
      setAdminId(props.adminUser.id)
    }
  }, [JSON.stringify(props.adminUser)])

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    sendLogout().then(() => {
      handleClose()
    })
  }

  const handleChangeStatus = () => {
    const isSupportAdmin = props.adminUser.botAccesses
      .find((bot) => bot.botId === Number(props.botId))?.role === ROLE.CLIENT

    updateAccountSettings(props.adminUser.id, {
      ...props.accountSettings,
      active: !props.accountSettings.active,
    }).then(() => {
      if (props.botId && !isSupportAdmin) {
        loadBotAccessors(props.botId)
      }
    })
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // anchorRef.current.focus();
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
      {isLoader && (
        <div className={props.classes.root}>
          <ClickAwayListener onClickAway={handleClose}>
            <>
              <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                data-autotest={'logout-menu-popup-button'}
                className={props.classes.button}>
                <div style={imgContainerStyles}>
                  <img
                    className={props.classes.userImg}
                    src={getImage}
                    alt="user photo"
                  />
                  <span style={{ ...statusStyles, background: activeColor }} />
                </div>
                {props.accountSettings && <p>{props.accountSettings.name}</p>}
                <span
                  style={{
                    transform: open && 'rotate(180deg)',
                    marginLeft: '8px',
                  }}>
                  <DownArrowIcon />
                </span>
              </Button>
              <Popper
                transition
                disablePortal
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="right-end"
                className={props.classes.popper}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}>
                    <Paper>
                      <MenuList
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        className={props.classes.list}>
                        <MenuItem
                          onClick={handleChangeStatus}
                          className={props.classes.listItem}>
                          <span>I am available</span>
                          <PrimaryColorSwitch
                            checked={props.accountSettings.active}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </MenuItem>
                        {props.location.pathname !== '/account-settings' &&
                        props.redirectToAccountSettings && (
                          <MenuItem
                            onClick={props.redirectToAccountSettings}
                            className={props.classes.listItem}>
                            Account Settings
                          </MenuItem>
                        )}

                        {props.location.pathname !== '/account-settings' &&
                        !props.redirectToAccountSettings && (
                          <MenuItem className={props.classes.listItem}>
                            <NavLink
                              to={{
                                pathname: '/account-settings',
                                state: { from: props.location.pathname },
                              }}>
                              Account Settings
                            </NavLink>
                          </MenuItem>
                        )}
                        <MenuItem
                          data-autotest={'logout-popup-item'}
                          onClick={handleLogout}
                          className={props.classes.listItem}>
                          Log out
                        </MenuItem>
                      </MenuList>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          </ClickAwayListener>
        </div>
      )}
    </>
  )
}
