import React, {FC} from 'react'
import classes from './styles.module.scss'

interface RoundCheckboxProps {
  label?: string
  onChange: (value: boolean) => void
  checked: boolean
}

const RoundCheckbox: FC<RoundCheckboxProps> = ({ onChange, label, checked}) => {

  return (
    <div className={classes.container} onClick={() => onChange(!checked)}>
      <div className={classes.checkbox} style={{ border: checked ? '2px solid #0B860B' : '2px solid #6D7686'}}>
        {checked && <div style={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: '#0B860B',
        }} />}
      </div>
      <span className={classes.label}>{label}</span>
    </div>
  )
}

export default RoundCheckbox