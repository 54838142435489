import React from 'react'
import * as S from './CustomDonutChart.style'
import 'react-chartjs-2'
import {circleChartColors} from '../../../../dashboard/constants/channelColors'
import NoChartData from '../../../../../uiKit/NoChartData'
import {PieChart} from 'react-chartkick'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import {DownloadDonutChartButton} from './DownloadDonutChartButton'

interface CustomDonutChartProps {
    title: string
    loading: boolean
    fileName?: string
    chartData: any[][]
}

export const CustomDonutChart: React.FC<CustomDonutChartProps> = ({
  title,
  loading,
  fileName,
  chartData,
}) => {

  const libraryOptions = {
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return data.labels[tooltipItem.index]
        },
      },
    },
  }

  const renderComponent = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true}/>
        </S.LoaderContainer>
      )
    } else if (chartData?.length) {
      return (
        <PieChart
          donut={true}
          data={chartData}
          colors={circleChartColors}
          legend={'bottom'}
          library={libraryOptions}
        />
      )
    } else {
      return (
        <S.LoaderContainer>
          <NoChartData text={''}/>
        </S.LoaderContainer>
      )
    }
  }

  return (
    <S.Container>
      <S.HeaderWrapper>
        <S.Title>{title}</S.Title>
        <S.Download>
          <DownloadDonutChartButton fileName={fileName} chartData={chartData}/>
        </S.Download>
      </S.HeaderWrapper>
      <S.ChartWrapper>{renderComponent()}</S.ChartWrapper>
    </S.Container>
  )
}
