export const channelColors: Map<string, string> = new Map<string, string>([
  ['All channels', '#0B860B'],
  ['Messenger', '#5A9BD4'],
  ['Telegram', '#FD7F4A'],
  ['Widget', '#F3B116'],
  ['Whatsapp (Amio)', '#093A3E'],
  ['Whatsapp (Dialog360)', '#4CDBA7'],
  ['Twilio', '#5A98F7'],
  ['Instagram', '#e8146c'],
  ['Twilio', '#9E67AB'],
  ['Instagram', '#e8146c'],
])

export const circleChartColors = [
  '#4CDBA7',
  '#5A9BD4',
  '#FD7F4A',
  '#7CB342',
  '#FF7FAA',
  '#4CDBF7',
  '#FFA800',
  '#0CA3F2',
  '#12F672',
]

export const color = { r: 0, g: 128, b: 0 }
export const gridItemsCount = 5
