import React from 'react'
import * as S from './CustomDataChartGroup.style'
import UsersDataCharts from '../CustomDataChart/UsersDataCharts'
import UsersPriceCharts from '../CustomDataChart/UsersPriceCharts'

export const CustomDataChartGroup: React.FC = () => {

  return (
    <S.Wrapper>
      <UsersDataCharts/>
      <UsersPriceCharts/>
    </S.Wrapper>
  )
}
