import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { LineChart } from 'react-chartkick'
import 'react-chartjs-2'

import LoaderSmall from 'uiKit/loaders/loaderSmall'
import NoChartData from 'uiKit/NoChartData'

import { channelColors } from '../../constants/channelColors'
import { noDataText } from '../../constants/chartsTexts'
import { isObjectEmpty } from 'helpers/isObjectEmpty'
import { sortArrayOfArraysByIndex } from 'helpers/sortArrayOfArraysByIndex'
import { ChartDataParameterType, LinePointType, usersChartDataType } from 'models/DashboardTypes'
import { DateControlContext } from 'contexts/DateControlContext'
import { loadUsersStatistics } from '../../api/dashboard'
import { clearUsersStatistics } from '../../actions/usersStatistics'

import * as S from './AreaChart.style'
import CheckboxesWrap from '../CheckboxesWrap'

interface Props {
  usersStatistics: usersChartDataType
  isMultipleChannels: boolean
  botId: string
}

const AreaChart: React.FC<Props> = ({ usersStatistics: chartData, isMultipleChannels, botId }) => {
  const [enabledPlatforms, setEnabledPlatforms] = useState([
    'Telegram',
    'Whatsapp (Dialog360)',
    'Messenger',
    'Twilio',
    'All channels',
    'Widget',
    'Instagram',
  ])
  const [loading, setLoading] = useState(false)
  const { startDate, endDate } = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    clearUsersStatistics()
    loadUsersStatistics(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).finally(() =>
      setLoading(false),
    )
  }, [endDate])

  const getDataForLineChart = (points: Map<string, LinePointType[]>) => {
    const resultData: ChartDataParameterType[] = Object.values(points)[0]
      ?.map(({ platform }, index) => {
        const data = {}
        Object.keys(points).forEach(key => {
          data[key] = points[key][index].userCount
        })
        return { name: platform, data }
      })
      ?.filter(resultEntry => {
        return isMultipleChannels ? enabledPlatforms.includes(resultEntry.name) : resultEntry.name === 'All channels'
      })
    return resultData
  }

  const getColorsForLineChart = (chartData: ChartDataParameterType[]) => {
    return chartData?.map(({ name }) => channelColors.get(name))
  }

  const handleCheckboxChange = (value: boolean, platform: string) => {
    let copyEnabledPlatforms = Object.assign([], enabledPlatforms)
    if (value) copyEnabledPlatforms.push(platform)
    else {
      if (copyEnabledPlatforms.length < 2) return
      copyEnabledPlatforms = copyEnabledPlatforms.filter(e => e !== platform)
    }
    setEnabledPlatforms(copyEnabledPlatforms)
  }

  const getKeysOrderByValuesDesc = (map: Map<string, number>) => {
    return sortArrayOfArraysByIndex(Object.entries(map), 1, 'desc').map(pair => pair[0])
  }

  const lineChartData = useMemo(() => chartData && getDataForLineChart(chartData.points), [chartData, enabledPlatforms])
  const colors = useMemo(() => getColorsForLineChart(lineChartData), [lineChartData])
  const linePoints = useMemo(
    () =>
      chartData &&
      !isObjectEmpty(chartData.usersBetweenDates) &&
      ['All channels'].concat(getKeysOrderByValuesDesc(chartData.usersBetweenDates)),
    [chartData],
  )
  const isData = useMemo(() => chartData?.points && !!Object.keys(chartData.points).length, [chartData])

  const renderComponent = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true} />
        </S.LoaderContainer>
      )
    } else if (isData) {
      return (
        <>
          <LineChart data={lineChartData} colors={colors} min={0} legend={false} />
          {isMultipleChannels && (
            <CheckboxesWrap
              linePoints={linePoints}
              enabledPlatforms={enabledPlatforms}
              onChange={handleCheckboxChange}
            />
          )}
        </>
      )
    } else {
      return <NoChartData text={noDataText} />
    }
  }

  return renderComponent()
}

const mapStateToProps = state => ({ usersStatistics: state.usersStatistics })

export default connect(mapStateToProps)(AreaChart)
