import api from '../../../services/api'
import {BASE_URL} from '../../../configs'
import {
  setProductTypeChartData,
  setFlavoursChartData,
  setStrainTypesChartData,
  setAveragePrice,
  setTotalPrice,
  setUsers,
  setFinishedUsers,
  setCartAddsInfluencedUsers,
  setTopBrandsData
} from '../actions/customCharts'

export async function getUserStatisticsCount(botId: number, from, to) {
  try {
    const data = await api.get(`${BASE_URL}/chart/${botId}/users?from=${from}&to=${to}`)
    if (data) {
      setUsers(data)
      setFinishedUsers(data)
      setCartAddsInfluencedUsers(data)
    }
  } catch (error) {
    console.log(error.message)
  }
}

export async function getAverageAndTotalPrices(botId: number, from, to) {
  try {
    const data = await api.get(`${BASE_URL}/chart/${botId}/prices?from=${from}&to=${to}`)
    if (data) {
      setTotalPrice(data)
      setAveragePrice(data)
    }
  } catch (error) {
    console.log(error.message)
  }
}

export async function getProductTypesDonutChatData(botId: number, from, to) {
  try {
    const data = await api.get(`${BASE_URL}/chart/${botId}/productTypes?from=${from}&to=${to}`)
    setProductTypeChartData(data)
  } catch (error) {
    console.log(error.message)
  }
}

export async function getFlavoursDonutChatData(botId: number, from, to) {
  try {
    const data = await api.get(`${BASE_URL}/chart/${botId}/flavours?from=${from}&to=${to}`)
    setFlavoursChartData(data)
  } catch (error) {
    console.log(error.message)
  }
}

export async function getStrainTypesDonutChatData(botId: number, from, to) {
  try {
    const data = await api.get(`${BASE_URL}/chart/${botId}/strainTypes?from=${from}&to=${to}`)
    setStrainTypesChartData(data)
  } catch (error) {
    console.log(error.message)
  }
}

export async function getTopBrandsData(botId: number, from, to) {
  try {
    const data = await api.get(`${BASE_URL}/chart/${botId}/brands?from=${from}&to=${to}`)
    setTopBrandsData(data)
  } catch (error) {
    console.log(error.message)
  }
}
