import styled from 'styled-components'

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  justify-content: center;
`

const Container = styled.div`
  width: 403px;
  height: 100%;
  min-height: 428px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.09);
`

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 24px 40px 40px 40px;
`

const Title = styled.span`
  color: #093A3E;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
`

const Download = styled.span`
  color: #0B860B;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`

const ChartWrapper = styled.div`
  height: 100%;
  margin-bottom: 40px;
`

export { LoaderContainer, Container, HeaderWrapper, Title, Download, ChartWrapper }
