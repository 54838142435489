import React, { FC, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import FunnelsWrap from '../FunnelsWrap'
import DateControl from 'uiKit/DateControlNew'
import Headline from 'uiKit/texts/Headline'
import LoaderScreen from 'uiKit/loaders/loaderScreen'

import { getBot } from '../../../home/api/bots'
import { getFlows } from '../../../flowBuilder/api/flow'
import { clearFunnelsState } from '../../actions/funnels'
import { AllAnalyticsType } from 'models/AnalyticsType'
import { FunnelType } from 'models/FunnelTypes'

import * as S from './Analytics.style'
import {ActiveUsersChart} from '../ActiveUsersChart/ActiveUsersChart'
import {usersChartDataType} from '../../../../models/DashboardTypes'
import {CustomDataChartGroup} from '../CustomDataChartGroup/CustomDataChartGroup'
import {CustomDonutChartGroup} from '../CustomDonutChartGroup/CustomDonutChartGroup'
import BrandsTable from '../BrandsTable/BrandsTable'

interface Props {
  usersStatistics: usersChartDataType
  match: any
  analytics: AllAnalyticsType
  funnels: FunnelType[]
  botId: number
}

const Analytics: FC<Props> = ({ usersStatistics, match, botId }) => {
  const containerEndRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getBot(match.params.botId)
    getFlows(match.params.botId)
    return clearFunnelsState
  }, [])

  const handleScrollToBottom = () => {
    if (containerEndRef.current) {
      containerEndRef.current.scrollIntoView()
    }
  }

  return (
    <S.Container ref={containerRef} loading={!botId}>
      <S.HeadlineWrap>
        <Headline title="Analytics" />
        <DateControl />
      </S.HeadlineWrap>

      {botId ? (
        <>
          <CustomDataChartGroup/>
          <ActiveUsersChart botId={botId} usersStatistics={usersStatistics}/>
          <CustomDonutChartGroup/>
          <BrandsTable/>
          <FunnelsWrap scrollToBottom={handleScrollToBottom} />
        </>
      ) : (
        <LoaderScreen />
      )}

      <div ref={containerEndRef} />
    </S.Container>
  )
}

const mapStateToProps = (state: {
  usersStatistics: usersChartDataType;
  analytics: AllAnalyticsType;
  funnels: FunnelType[];
  activeBot: any
}) => ({
  usersStatistics: state.usersStatistics,
  analytics: state.analytics,
  funnels: state.funnels,
  botId: state.activeBot?.id,
})

export default withRouter(connect(mapStateToProps)(Analytics))
