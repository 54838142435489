import React, {useContext, useEffect, useMemo, useState} from 'react'
import {DateControlContext} from '../../../../contexts/DateControlContext'
import {CustomDonutChart} from './common/CustomDonutChart'
import {getFlavoursDonutChatData} from '../../api/customCharts'
import {PieChartDTO} from '../../model/customChartModels'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

interface FlavourDonutChartProps {
    botId: number
    flavoursChartData?: PieChartDTO[]
}

const FlavourDonutChart: React.FC<FlavourDonutChartProps> = ({botId, flavoursChartData}) => {

  const [loading, setLoading] = useState(false)
  const {startDate, endDate} = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    getFlavoursDonutChatData(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  const data = useMemo(() => flavoursChartData && flavoursChartData.map(cd => {
    return [cd.type, cd.count]
  }), [flavoursChartData])

  return <CustomDonutChart
    title={'Flavor'}
    loading={loading}
    fileName={'flavor'}
    chartData={data}
  />
}

const mapStateToProps = (state) => ({
  flavoursChartData: state.flavoursChartData,
  botId: state.activeBot?.id,
})

export default withRouter(connect(mapStateToProps)(FlavourDonutChart))
