import React from 'react'
import DownloadButton from '../../../../../uiKit/buttons/DownloadButton'

interface CustomDonutChartProps {
  fileName: string
  chartData: any[][]
}

export const DownloadDonutChartButton: React.FC<CustomDonutChartProps> = ({fileName, chartData}) => {

  return <DownloadButton
    csvData={chartData}
    csvFilename={fileName}
    csvHeaders={['Type', 'Count']}
    csvOnDownload={() => chartData}
  />
}
