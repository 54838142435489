import React from 'react'
import * as S from './BrandsTable.style'
import {SortOrder} from './BrandsTable'
import {SortDirectionImage} from './SortDirectionImage'

interface BrandsTableHeaderProps {
    sortOrder: SortOrder
    toggleSortOrder: () => void
}

export const BrandsTableHeader: React.FC<BrandsTableHeaderProps> = ({sortOrder, toggleSortOrder}) => {

  return <S.TableHeader>
    <S.BrandColumnName>Brand Name</S.BrandColumnName>
    <S.ClicksColumnName onClick={toggleSortOrder}>Clicks <SortDirectionImage/></S.ClicksColumnName>
  </S.TableHeader>
}
