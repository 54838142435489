import { store } from '../../../index'
import {BrandDTO, PieChartDTO, PricesDTO, UserStatisticDTO} from '../model/customChartModels'

export const SET_USERS = 'SET_USERS'
export const SET_FINISHED_USERS = 'SET_FINISHED_USERS'
export const SET_CART_ADDS_INFLUENCED_USERS = 'SET_CART_ADDS_INFLUENCED_USERS'
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE'
export const SET_AVERAGE_PRICE = 'SET_AVERAGE_PRICE'
export const SET_PRODUCT_TYPES_CHART_DATA = 'SET_PRODUCT_TYPES_CHART_DATA'
export const SET_FLAVOURS_CHART_DATA = 'SET_FLAVOURS_CHART_DATA'
export const SET_STRAIN_TYPES_CHART_DATA = 'SET_STRAIN_TYPES_CHART_DATA'
export const SET_TOP_BRANDS_DATA = 'SET_TOP_BRANDS_DATA'

export const setUsers = (userStatisticDTO: UserStatisticDTO) => {
  store.dispatch({
    type: SET_USERS,
    payload: {
      userStatistics: userStatisticDTO.users
    },
  })
}

export const setFinishedUsers = (userStatisticDTO: UserStatisticDTO) => {
  store.dispatch({
    type: SET_FINISHED_USERS,
    payload: {
      finishedUserStatistics: userStatisticDTO.finishedUsers
    },
  })
}

export const setCartAddsInfluencedUsers = (userStatisticDTO: UserStatisticDTO) => {
  store.dispatch({
    type: SET_CART_ADDS_INFLUENCED_USERS,
    payload: {
      cartAddsInfluenced: userStatisticDTO.cartAddsInfluenced
    },
  })
}

export const setTotalPrice = (averageAndTotalPrices: PricesDTO) => {
  store.dispatch({
    type: SET_TOTAL_PRICE,
    payload: {
      totalPrice: averageAndTotalPrices.totalPrice
    },
  })
}

export const setAveragePrice = (averageAndTotalPrices: PricesDTO) => {
  store.dispatch({
    type: SET_AVERAGE_PRICE,
    payload: {
      averagePrice: averageAndTotalPrices.averagePrice
    },
  })
}

export const setProductTypeChartData = (productTypesChartData: PieChartDTO[]) => {
  store.dispatch({
    type: SET_PRODUCT_TYPES_CHART_DATA,
    payload: {
      productTypesChartData,
    },
  })
}

export const setFlavoursChartData = (flavoursChartData: PieChartDTO[]) => {
  store.dispatch({
    type: SET_FLAVOURS_CHART_DATA,
    payload: {
      flavoursChartData,
    },
  })
}

export const setStrainTypesChartData = (strainTypesChartData: PieChartDTO[]) => {
  store.dispatch({
    type: SET_STRAIN_TYPES_CHART_DATA,
    payload: {
      strainTypesChartData,
    },
  })
}

export const setTopBrandsData = (topBrandsData: BrandDTO[]) => {
  store.dispatch({
    type: SET_TOP_BRANDS_DATA,
    payload: {
      topBrandsData,
    },
  })
}
