import React, {useContext, useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {DateControlContext} from '../../../../contexts/DateControlContext'
import {
  getAverageAndTotalPrices
} from '../../api/customCharts'
import {CustomDataChart} from './common/CustomDataChart'

interface UsersPriceChartsProps {
  totalPriceChartData: number
  averagePriceChartData: number
  botId: number
}

const UsersPriceCharts: React.FC<UsersPriceChartsProps> = ({
  totalPriceChartData,
  averagePriceChartData,
  botId
}) => {

  const [loading, setLoading] = useState(false)
  const {startDate, endDate} = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    getAverageAndTotalPrices(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  return <>
    <CustomDataChart
      data={averagePriceChartData}
      title={'AVG Item Price'}
      loading={loading}
      fractionDigits={2}
      showDollarSign={true}
    />
    <CustomDataChart
      data={totalPriceChartData}
      title={'Total Revenue Influenced'}
      loading={loading}
      fractionDigits={2}
      showDollarSign={true}
    />
  </>
}

const mapStateToProps = (state) => ({
  totalPriceChartData: state.totalPriceChartData,
  averagePriceChartData: state.averagePriceChartData,
  botId: state.activeBot?.id,
})

export default withRouter(connect(mapStateToProps)(UsersPriceCharts))
