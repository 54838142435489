import styled from 'styled-components'

const Container = styled.div`
  width: 240px;
  height: 148px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.09);
`

const Value = styled.span`
  color: #0B860B;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
`

const Title = styled.span`
  color: #616581;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px;
`

export { Container, Value, Title }
