import React from 'react'
import {BrandDTO} from '../../model/customChartModels'
import * as S from './BrandsTable.style'

interface BrandsTableRowProps {
    brand: BrandDTO
    index: number
}

export const BrandsTableRow: React.FC<BrandsTableRowProps> = ({brand, index}) => {

  return <S.TableRow index={index}>
    <S.IndexValue>{index + 1}.</S.IndexValue>
    <S.BrandValue>{brand.brand}</S.BrandValue>
    <S.ClicksValue>{brand.clicks}</S.ClicksValue>
  </S.TableRow>
}
