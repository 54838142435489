import React, {useEffect, useState} from 'react'
import classes from '../GreetingMessagePopupAppearanceSection/styles.module.scss'
import Switch from '../../../../uiKit/Switch'
import LogoDropzone from '../LogoDropzone'
import Label from '../../../../uiKit/texts/Label'
import Input from '../../../../uiKit/inputs/Input'
import Tooltip from '../../../../uiKit/TooltipInfo/Tooltip'
import RoundCheckbox from '../../../../uiKit/inputs/RoundCheckbox'
import GreetingMessagePopupPreviewScreen from '../GreetingMessagePopupPreviewScreen'
import {WidgetSettingsType} from '../../../../models/WidgetSettingsTypes'
import {OptionItemType} from '../../../../models/MessageTypes'
import {setErrorsPxField} from '../../../../helpers/setErrors/setErrors'
import {PXSpan} from './PXSpan'
import {useDebounce} from '../../../../hooks/useDebounce'

interface Props {
  widgetSettings: WidgetSettingsType
  selectedLanguage: OptionItemType
  handleUpdateWidgetSettings: (value: string | boolean, field: string) => void
}

const GreetingMessagePopupAppearanceSection: React.FC<Props> = ({ widgetSettings, selectedLanguage,
  handleUpdateWidgetSettings }) => {

  const [logoSize, setLogoSize] = useState<number | null>(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings.popupLogoSize || null
  )
  const [alignment, setAlignment] = useState<'LEFT' | 'RIGHT'>(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings.alignment || null
  )
  const [bottomSpacing, setBottomSpacing] = useState<number | null>(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings.bottomSpacing || null
  )
  const [sideSpacing, setSideSpacing] = useState<number | null>(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings.sideSpacing || null
  )
  const [errorsLogoSize, setErrorsLogoSize] = useState<string[]>([])
  const [errorsBottomSpacing, setErrorsBottomSpacing] = useState<string[]>([])
  const [errorsSideSpacing, setErrorsSideSpacing] = useState<string[]>([])
  const debouncedBottomSpacing = useDebounce(bottomSpacing, 1000)
  const debouncedSideSpacing = useDebounce(sideSpacing, 1000)


  useEffect(() => {
    correctBottomSpacingField(debouncedBottomSpacing)
  }, [debouncedBottomSpacing])

  useEffect(() => {
    correctSideSpacingField(debouncedSideSpacing)
  }, [debouncedSideSpacing])

  useEffect(() => {
    handleUpdateAlignment()
  }, [alignment])

  const handleUpdateLogoSize = (value: number) => {
    const isValid = validateLogoSizeField(value, setErrorsLogoSize)

    if (isValid) {
      handleUpdateWidgetSettings(value.toString(10), 'greetingMessagePopupSettings.popupLogoSize')
    }
  }

  const handleUpdateAlignment = () => {
    handleUpdateWidgetSettings(alignment, 'greetingMessagePopupSettings.alignment')
  }

  const handleUpdateBottomSpacing = (value: number) => {
    const isValid = validateBottomSpacingField(value, setErrorsBottomSpacing)

    if (isValid) {
      handleUpdateWidgetSettings(value.toString(10), 'greetingMessagePopupSettings.bottomSpacing')
    }
  }

  const handleUpdateSideSpacing = (value: number) => {
    const isValid = validateSideSpacingField(value, setErrorsSideSpacing)

    if (isValid) {
      handleUpdateWidgetSettings(value.toString(10), 'greetingMessagePopupSettings.sideSpacing')
    }
  }

  const handleLogoSize = event => {
    validateLogoSizeField(event.target.value, setErrorsLogoSize)
    const inputValue = event.target.value
    const numericValue = parseInt(inputValue, 10)

    if (!isNaN(numericValue)) {
      setLogoSize(numericValue)
    } else {
      setLogoSize(null)
    }
  }

  const correctBottomSpacingField = (numericValue) => {
    if (!isNaN(numericValue)) {
      const minValue = 10
      const maxValue = 400
      if (numericValue < minValue) {
        setBottomSpacing(minValue)
      } else if (numericValue > maxValue) {
        setBottomSpacing(maxValue)
      } else {
        setBottomSpacing(numericValue)
      }
    } else {
      setBottomSpacing(0)
    }
    setErrorsBottomSpacing([])
  }

  const correctSideSpacingField = (numericValue) => {
    if (!isNaN(numericValue)) {
      const minValue = 10
      if (numericValue < minValue) {
        setSideSpacing(minValue)
      } else {
        setSideSpacing(numericValue)
      }
    } else {
      setSideSpacing(0)
    }
    setErrorsSideSpacing([])
  }

  const handleBottomSpacing = event => {
    validateBottomSpacingField(event.target.value, setErrorsBottomSpacing)
    const inputValue = event.target.value
    const numericValue = inputValue ? parseInt(inputValue, 10) : 0
    setBottomSpacing(numericValue)
  }

  const handleSideSpacing = event => {
    validateSideSpacingField(event.target.value, setErrorsSideSpacing)
    const inputValue = event.target.value
    const numericValue = inputValue ? parseInt(inputValue, 10) : 0
    setSideSpacing(numericValue)
  }

  const validateLogoSizeField = (value: number, callback: (a: any) => void) => {
    const errors = setErrorsPxField(value, 40, 100)
    callback(errors)

    return !errors.length
  }

  const validateSideSpacingField = (value: number, callback: (a: any) => void) => {
    const errors = setErrorsPxField(value, 10, 300)
    callback(errors)

    return !errors.length
  }

  const validateBottomSpacingField = (value: number, callback: (a: any) => void) => {
    const errors = setErrorsPxField(value, 10, 400)
    callback(errors)

    return !errors.length
  }

  return (
    <div className={classes.greetingMessagePopupSection}>
      <h2 className={classes.subHeaderText}>Greeting message popup</h2>
      <hr className={classes.horizontalLine}/>
      <div className={classes.greetingMessagePopupSectionContainer}>
        <div>
          <Switch
            label="Show greeting message popup"
            checked={widgetSettings?.languageConfigs[selectedLanguage.value]?.doShowPopupMessagePreview}
            onChange={e => handleUpdateWidgetSettings(e.target.checked, 'doShowPopupMessagePreview')}
          />
          <div className={classes.logoSection}>
            <LogoDropzone
              value={
                widgetSettings?.languageConfigs[selectedLanguage.value]?.greetingMessagePopupSettings
                  .popupLogoUrl || ''}
              onChange={(value: string) =>
                handleUpdateWidgetSettings(value, 'greetingMessagePopupSettings.popupLogoUrl')}
              dropzoneType={'POPUP_LOGO'}
            />
            <div className={classes.logoSizeInput}>
              <Input
                title="Logo size"
                placeholder="Write logo size in pixels (e.g. 72 px)"
                value={logoSize || ''}
                onChange={handleLogoSize}
                handleEnterPress={() => handleUpdateLogoSize(logoSize)}
                onBlur={() => handleUpdateLogoSize(logoSize)}
                error={!!errorsLogoSize.length}
                errorMessage={errorsLogoSize[0]}
                tooltipText={'Customize logo size. Set the logo size between 40 and 100 pixels.'}
              />
              <PXSpan/>
            </div>
          </div>
          <div className={classes.popupPositioningSection}>
            <Label>
                Greeting message popup position
              <Tooltip tooltipId={'TooltipInfo-attribute'} tooltipText={'Customize greeting popup position. ' +
                    'Align it to the left or right side of the screen.'}/>
            </Label>
            <Label>Align to</Label>
            <div className={classes.alignSection}>
              <RoundCheckbox label={'Left'} checked={alignment === 'LEFT'}
                onChange={() => setAlignment('LEFT')}
              />
              <span className={classes.rightCheckbox}>
                <RoundCheckbox label={'Right'} checked={alignment === 'RIGHT'}
                  onChange={() => setAlignment('RIGHT')}
                />
              </span>
            </div>
            <div className={classes.spacingSection}>
              <div className={classes.bottomSpacing}>
                <Input
                  title="Bottom spacing"
                  placeholder="Write logo size in pixels (e.g. 72 px)"
                  value={bottomSpacing || ''}
                  onChange={(e) => handleBottomSpacing(e)}
                  handleEnterPress={() => handleUpdateBottomSpacing(bottomSpacing)}
                  onBlur={() => handleUpdateBottomSpacing(bottomSpacing)}
                  error={!!errorsBottomSpacing.length}
                  errorMessage={errorsBottomSpacing[0]}
                  tooltipText={'Set the spacing from the bottom to be within the range of 10 to 400 pixels.'}
                />
                <PXSpan/>
              </div>
              <div className={classes.sideSpacing}>
                <Input
                  title="Side spacing"
                  placeholder="Write logo size in pixels (e.g. 72 px)"
                  value={sideSpacing || ''}
                  onChange={handleSideSpacing}
                  handleEnterPress={() => handleUpdateSideSpacing(sideSpacing)}
                  onBlur={() => handleUpdateSideSpacing(sideSpacing)}
                  error={!!errorsSideSpacing.length}
                  errorMessage={errorsSideSpacing[0]}
                  tooltipText={'Set the spacing from the side to be between 10 and 300 pixels.'}
                />
                <PXSpan/>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.greetingMessagePopupPreviewScreenContainer}>
          <GreetingMessagePopupPreviewScreen
            bottomSpacing={bottomSpacing}
            sideSpacing={sideSpacing}
            greetingPopupLogoUrl={widgetSettings?.languageConfigs[selectedLanguage.value]
              ?.greetingMessagePopupSettings.popupLogoUrl || null}
            alignment={alignment}
          />
        </div>
      </div>
    </div>
  )
}

export default GreetingMessagePopupAppearanceSection
