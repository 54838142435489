import React from 'react'
import * as S from './CustomDonutChartGroup.style'
import FlavourDonutChart from '../CustomDonutChart/FlavourDonutChart'
import ProductTypesDonutChart from '../CustomDonutChart/ProuctTypesDonutChart'
import StrainTypesDonutChart from '../CustomDonutChart/StrainTypesDonutChart'

export const CustomDonutChartGroup: React.FC = () => {

  return (
    <S.Container>
      <ProductTypesDonutChart/>
      <FlavourDonutChart/>
      <StrainTypesDonutChart/>
    </S.Container>
  )
}
