import React, {useContext, useEffect, useState} from 'react'
import * as S from './BrandsTable.style'
import {DateControlContext} from '../../../../contexts/DateControlContext'
import {getTopBrandsData} from '../../api/customCharts'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import {BrandDTO} from '../../model/customChartModels'
import {BrandsTableRow} from './BrandsTableRow'
import NoChartData from '../../../../uiKit/NoChartData'
import {BrandsTableHeader} from './BrandsTableHeader'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {DownloadTopBrandsButton} from './DownloadTopBrandsButton'

export enum SortOrder {
  // eslint-disable-next-line no-unused-vars
  ASC = 'ASC',
  // eslint-disable-next-line no-unused-vars
  DESC = 'DESC'
}

interface BrandsTableProps {
    botId: number
    topBrandsData: BrandDTO[]
}

const BrandsTable: React.FC<BrandsTableProps> = ({botId, topBrandsData}) => {

  const [sortOrder, setSortOrder] = useState(SortOrder.DESC)
  const [loading, setLoading] = useState(false)
  const {startDate, endDate} = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    getTopBrandsData(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  const toggleSortOrder = () => {
    setSortOrder(sortOrder == SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC)
  }

  const renderContent = () => {
    if (loading) {
      return <S.LoaderContainer>
        <LoaderSmall showLoader={true}/>
      </S.LoaderContainer>
    } else {
      if (topBrandsData?.length) {
        return <>
          <BrandsTableHeader sortOrder={sortOrder} toggleSortOrder={toggleSortOrder}/>
          <S.Line/>
          {topBrandsData.sort((d1, d2) => {
            return sortOrder == SortOrder.ASC ? d1.clicks - d2.clicks : d2.clicks - d1.clicks
          }).map((d, index) => {
            return <BrandsTableRow key={index} brand={d} index={index}/>
          })}
        </>
      } else {
        return <NoChartData text={''}/>
      }
    }
  }

  return (
    <S.Container>
      <S.Header>
        <S.Title>Top 5 Brands</S.Title>
        <S.Download>
          <DownloadTopBrandsButton chartData={topBrandsData}/>
        </S.Download>
      </S.Header>
      <S.Line/>
      <S.TableWrapper>
        {renderContent()}
      </S.TableWrapper>
    </S.Container>
  )
}


const mapStateToProps = (state) => ({
  topBrandsData: state.topBrandsData,
  botId: state.activeBot?.id,
})

export default withRouter(connect(mapStateToProps)(BrandsTable))
