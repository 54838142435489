import {
  SET_USERS,
  SET_FINISHED_USERS,
  SET_CART_ADDS_INFLUENCED_USERS,
  SET_TOTAL_PRICE,
  SET_AVERAGE_PRICE,
  SET_PRODUCT_TYPES_CHART_DATA,
  SET_FLAVOURS_CHART_DATA,
  SET_STRAIN_TYPES_CHART_DATA,
  SET_TOP_BRANDS_DATA
} from '../actions/customCharts'

export const userStatisticsData = (state = 0, {type, payload}) => {
  switch (type) {
    case SET_USERS:
      return payload.userStatistics || 0
    default:
      return state
  }
}

export const finishedUserStatisticsData = (state = 0, {type, payload}) => {
  switch (type) {
    case SET_FINISHED_USERS:
      return payload.finishedUserStatistics || 0
    default:
      return state
  }
}

export const cartAddsInfluencedData = (state = 0, {type, payload}) => {
  switch (type) {
    case SET_CART_ADDS_INFLUENCED_USERS:
      return payload.cartAddsInfluenced || 0
    default:
      return state
  }
}

export const totalPriceChartData = (state = 0, {type, payload}) => {
  switch (type) {
    case SET_TOTAL_PRICE:
      return payload.totalPrice || 0
    default:
      return state
  }
}

export const averagePriceChartData = (state = 0, {type, payload}) => {
  switch (type) {
    case SET_AVERAGE_PRICE:
      return payload.averagePrice || 0
    default:
      return state
  }
}

export const productTypesChartData = (state = [], {type, payload}) => {
  switch (type) {
    case SET_PRODUCT_TYPES_CHART_DATA:
      return payload.productTypesChartData || []
    default:
      return state
  }
}

export const flavoursChartData = (state = [], {type, payload}) => {
  switch (type) {
    case SET_FLAVOURS_CHART_DATA:
      return payload.flavoursChartData || []
    default:
      return state
  }
}

export const strainTypesChartData = (state = [], {type, payload}) => {
  switch (type) {
    case SET_STRAIN_TYPES_CHART_DATA:
      return payload.strainTypesChartData || []
    default:
      return state
  }
}

export const topBrandsData = (state = [], {type, payload}) => {
  switch (type) {
    case SET_TOP_BRANDS_DATA:
      return payload.topBrandsData || []
    default:
      return state
  }
}
