import styled from 'styled-components'

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`

const Container = styled.div`
  width: 100%;
  height: 502px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.09);
`

const Header = styled.div`
  width: 220px;
  display: flex;
  margin: 40px 40px 24px 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid #3540521A;
`

const Title = styled.span`
  color: #3A3F62;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
`

const Download = styled.span`
  cursor: pointer;
  color: #0B860B;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`

const TableWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 40px;
`

const TableHeader = styled.div`
  display: flex;
  width: 100%;
  margin-left: 52px;
  padding-bottom: 16px;
`

const BrandColumnName = styled.span`
  color: #3A3F62;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  width: 350px;
`

const ClicksColumnName = styled.span`
  color: #3A3F62;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`

const TableRow = styled.div<{ index: number }>`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ index }) => (index % 2 !== 0 && '#E9F8F2')};
`

const IndexValue = styled.span`
  color: #3A3F62;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 24px;
`

const BrandValue = styled.span`
  color: #3A3F62;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 350px;
  margin-left: 16px;
`

const ClicksValue = styled.span`
  color: #3A3F62;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

export {
  LoaderContainer,
  Container,
  Header,
  Line,
  Title,
  Download,
  TableWrapper,
  TableHeader,
  BrandColumnName,
  ClicksColumnName,
  TableRow,
  IndexValue,
  BrandValue,
  ClicksValue
}
