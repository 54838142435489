export const PRIMARY = 'primary'
// https://colors.artyclick.com/color-name-finder/
export const KLEIN_BLUE = '#093A3E'
export const CAMARONE_GREEN = '#006401'
export const GEM_BLUE = '#093A3E'
export const CINNAMON_YELLOW = '#7D4400'
export const TAMARILLO_RED = '#A21010'
export const SPUN_PEARL_GREY = '#B0B2C0'
export const PERMISSION_RED = '#ff624c'
export const WHITE = '#ffffff'
export const RIBBON_BLUE = '#093A3E'
export const DULL_LAVANDER_BLUE = '#093A3E'
export const DANUBE_BLUE = '#093A3E'
export const CORNFLOWER_BLUE = '#093A3E'
export const PLUGGI_MAIN = '#33D533'
export const PLUGGI_FORESTGREEN = '#093A3E'
export const PLUGGI_SELECT_MENU = '#093A3E26'
export const PLUGGI_DARK_GREEN = '#0B860B'
export const BLUE = '#093A3E'
export const COMET_GREY = '#616581'
export const SELAGO_BLUE = '#f3f7fe'
export const SHARK_BLACK = '#212529'
export const ATHENS_GRAY = '#fafafb'
export const MISCHKA_GRAY = '#D7D8DF'
export const OXFORD_BLUE = '#354052'
export const FIORD_BLUE = '#093A3E'
export const ATHENS_GRAY_DARK = '#EBEBEF'
export const SOLITUDE = '#dfecff'
export const MALACHITE_GREEN = '#11C314'
export const ROYAL_BLUE = '#093A3E'
export const GHOST_GRAY = '#C7CAD6'
export const SELAGO_GRAY = '#F7FAFE'
export const HAWKES_BLUE = '#EEF5FE'
export const PERIWINKLE = '#CADFFF'
export const MINE_SHAFT = '#393939'
export const PALE_SKY = '#6D7686'
export const EGYPTIAN_BLUE = '#093A3E'
export const PEPPERMINT = '#e5f8e2'
export const LIGHT_PEACH = '#ffd8aa'
export const TROPICAL_BLUE = '#093A3E'
export const HALF_DUTCH_WHITE = '#fff8db'
export const PEACH_SCHNAPPS = '#ffdcd8'
export const CADET_BLUE = '#acb0c0'
export const BRANDEIS_BLUE = '#E9F8F2'
export const MEDIUM_GREEN = '#20b038'
export const RIVER_BED = '#464960'
export const JORDY_BLUE = '#093A3E'
export const GRAY = '#808080'
export const DEEP_SKY_BLUE = '#093A3E'
export const MERCURY = '#093A3E'
export const CYAN_BLUE = '#093A3E'
export const PASTEL_ORANGE = '#FF974C'
export const BLUEBERRY_BLUE = '#0E8A0E'
export const YELLOW_ORANGE = '#F3B116'
export const PLATINUM = '#E5E5E5'
export const GAINSBORO = '#DDDDDD'
export const EGG_WHITE = '#FEF2BD'
