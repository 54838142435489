import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { cancelBroadcast, loadBroadcasts } from '../api/broadcast'
import Modal from '@material-ui/core/Modal'
import ConfirmModal from './ConfirmModal'
import moment from 'moment/moment'
import Pagination from '../../../uiKit/table/Pagination'
import Headline from '../../../uiKit/texts/Headline'
import NoData from '../../nlp/components/NoNlpData'
import LoaderSmall from '../../../uiKit/loaders/loaderSmall'
import {NewDeleteIcon} from '../../../uiKit/icons/NewDeleteIcon'
import {customStyles} from '../../../uiKit/AtomSelect/styles'

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  title: {
    fontSize: 24,
    color: theme.defaults.headerText,
  },
  input: {
    padding: '10px',
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'var(--color-white)',
  },
  inputHolder: {
    border: '1px solid var(--color-ghost-grey)',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'var(--color-white)',
    marginRight: 10,
    padding: 10,
    maxHeight: 45,
    minWidth: 400,
  },
  broadcastItem: {
    padding: 14,
    fontSize: 14,
    display: 'flex',
    maxHeight: 80,
    fontFamily: 'Lato, sans-serif',
    color: theme.defaults.text,
    position: 'relative',
    '&:hover .deleteIcon': {
      display: 'block',
    },
    animationDuration: '0.5s',
    animationName: 'appear',
  },
  name: {
    flexBasis: '20%',
    maxWidth: '20%',
    wordWrap: 'break-word',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
  },
  broadcastTableHeader: {
    fontWeight: 'bold',
  },
  atom: {
    flexBasis: '30%',
    maxWidth: '30%',
    wordWrap: 'break-word',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
  },
  time: {
    flexBasis: '30%',
    color: theme.defaults.text,
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    backgroundColor: 'red',
    borderRadius: 10,
    fontSize: 12,
    alignSelf: 'center',
    fontFamily: 'Lato, sans-serif',
    color: 'var(--color-white)',
  },
  deleteInt: {
    color: theme.defaults.text,
    cursor: 'pointer',
    position: 'absolute',
    display: 'none',
    right: 14,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  desc: {
    lineHeight: 17,
    fontSize: 14,
    color: theme.defaults.headerText,
  },
  titleFont: {
    fontSize: '24px',
    fontWeight: 'bold',
    fontFamily: 'Lato',
    color: theme.defaults.headerText,
  },
  headerWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  headerWrapItem: {
    flexBasis: '50%',
  },
  broadcastsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addNew: {
    backgroundColor: 'var(--color-white)',
    color: 'var(--color-button-primary)',
    border: '1px solid var(--color-button-primary)',
    borderRadius: 10,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: 40,
    margin: '50px 20px 20px',
    cursor: 'pointer',
  },
  '@global': {
    '@keyframes appear': {
      from: {
        position: 'relative',
        top: 30,
        opacity: 0,
      },
      to: {
        position: 'relative',
        top: 0,
        opacity: 1,
      },
    },
  },
})

const options = [
  {
    label: 'Latest first',
    value: 'Latest first',
  },
  {
    label: 'Active first',
    value: 'Active first',
  },
]

const description =
    // eslint-disable-next-line max-len
    'You have no Broadcasts yet. Add a new Broadcast to be able to send group message notifications to your target audience'

class NewBroadcastsList extends React.Component {
  closeConfirmModal = () => {
    this.setState({
      open: false,
      modalBroadcastId: null,
      modalBroadcastTitle: '',
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      rowsPerPage: 10,
      open: false,
      modalIntentId: 0,
      searchIntent: '',
      selectedOption: options[0],
      modalBroadcastId: null,
      modalBroadcastTitle: '',
    }

    loadBroadcasts(props.match.params.botId)
  }

  openConfirmModal() {
    this.setState({
      open: true,
    })
  }

  handleChangeOption(option) {
    this.setState({
      selectedOption: option,
    })
  }

  sortData(date1, date2) {
    if (this.state.selectedOption.value === 'Latest first') {
      return date2.id - date1.id
    } else {
      if (
        (date1.status !== 'Canceled' &&
          date1.status !== 'Done' &&
          date2.status === 'Canceled') ||
        date2.status === 'Done'
      ) {
        if (date2.status === 'Scheduled' && date1.status === 'In Progress') {
          return date2.id - date1.id
        }
        return date1.id - date2.id
      } else if (
        date2.status === 'Scheduled' &&
        date1.status !== 'In Progress'
      ) {
        return date2.id - date1.id
      } else {
        const c = new Date(date1.localDateTime) / 1000
        const d = new Date(date2.localDateTime) / 1000
        return d - c
      }
    }
  }

  handleChangeRowsPerPage(value) {
    this.setState({
      rowsPerPage: value,
    })
  }

  timeFunc() {
    if (this.props.activeBot.timezone > 0) {
      return `+ ${this.props.activeBot.timezone}`
    } else if (this.props.activeBot.timezone === 0) {
      return ' '
    } else {
      return ` ${this.props.activeBot.timezone}`
    }
  }

  handleChangePage(value) {
    this.setState({
      page: value,
    })
  }

  formatDatetime(broadcastDatetime) {
    return moment(new Date(broadcastDatetime)).format('DD MMM YYYY HH:mm')
  }

  calculateRead(n) {
    return (
      (n.read ? n.read : 0) +
      ' (' +
      (n.read && n.delivered
        ? (n.delivered === 0 ? 0 : (100 * n.read) / n.delivered) === 100
          ? n.delivered === 0
            ? 0
            : (100 * n.read) / n.delivered
          : (n.delivered === 0 ? 0 : (100 * n.read) / n.delivered).toFixed(1)
        : 0) +
      '%)'
    )
  }

  getRowStyles = index => {
    if ((index + 1) % 2 === 1) {
      return {
        background: 'rgba(255, 255, 255, 0)',
      }
    }
    return {
      background: 'var(--color-table-row-primary)',
      borderRadius: 10,
    }
  }

  getTimezone = broadcast => {
    return broadcast.timezone
      ? this.formatDatetime(broadcast.localDateTime)
      : moment(broadcast.localDateTime)
        .utcOffset(this.props.activeBot.timezone * 60 + moment().utcOffset())
        .format('DD MMM YYYY HH:mm')
  }

  getTimezoneLabel = timezone => {
    return timezone
      ? ' User timezone'
      : ` Bot timezone (UTC ${this.timeFunc()})`
  }

  getStatusColor = status => {
    switch (status) {
    case 'Done':
      return 'var(--color-malachite-green)'
    case 'Scheduled':
      return '#FFAA47'
    case 'Canceled':
      return 'var(--color-coral-red)'
    case 'In Progress':
      return '#093A3E'
    default:
      return '#093A3E'
    }
  }

  render() {
    const { classes } = this.props
    const data = this.props.broadcasts
    const { rowsPerPage, page } = this.state

    return (
      <div style={{ overflowY: 'auto', height: '100%' }}>
        <div className={classes.headerWrap}>
          <Headline className={classes.headerWrapItem} title={'Broadcasts'} />
          <div className={classes.broadcastsHeader}>
            <div onClick={this.props.handleBroadcastModalOpen} className={classes.addNew}>
              + Add Broadcast
            </div>

            {!!data?.length && (
              <div style={{ width: 200, margin: '50px 0 20px 0' }}>
                <Select
                  styles={customStyles}
                  options={options}
                  simpleValue
                  value={this.state.selectedOption}
                  onChange={value => this.handleChangeOption(value)}
                  searchable={true}
                />
              </div>
            )}
          </div>
        </div>
        {!data.length ? (
          <LoaderSmall />
        ) : (
          <div>
            {data.length ? (
              <div className={classes.tableWrapper}>
                <div className={classes.table}>
                  <div>
                    <div
                      className={classes.broadcastItem}
                      style={{
                        borderBottom: '1px solid var(--color-issabeline-grey)',
                        borderTop: 'none',
                        paddingBottom: 8,
                      }}>
                      <div className={`${classes.name} ${classes.broadcastTableHeader}`}>Name</div>
                      <div className={`${classes.atom} ${classes.broadcastTableHeader}`}>Message</div>
                      <div className={`${classes.name} ${classes.broadcastTableHeader}`}>Delivered</div>
                      <div className={`${classes.name} ${classes.broadcastTableHeader}`}>Read (%)</div>
                      <div className={`${classes.time} ${classes.broadcastTableHeader}`}>Time</div>
                      <div
                        className={`${classes.name} ${classes.broadcastTableHeader}`}
                        onClick={() => this.sortData()}>
                        Status
                      </div>
                    </div>
                    {data
                      .sort((a, b) => this.sortData(a, b))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((broadcast, index) => (
                        <div key={broadcast.id} style={this.getRowStyles(index)} className={classes.broadcastItem}>
                          <div className={classes.name}>{broadcast.name}</div>
                          <div className={classes.atom}>{broadcast.atom}</div>
                          <div className={classes.name}>{broadcast.delivered ? broadcast.delivered : 0}</div>
                          <div className={classes.name}>{this.calculateRead(broadcast)}</div>
                          <div className={classes.time}>
                            {this.getTimezone(broadcast)}
                            <br />
                            {this.getTimezoneLabel(broadcast.timezone)}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexBasis: '20%',
                            }}>
                            <span
                              className={classes.status}
                              style={{
                                backgroundColor: this.getStatusColor(broadcast.status),
                              }}>
                              {broadcast.status}
                            </span>
                          </div>
                          {(broadcast.status === 'Scheduled' || broadcast.status === 'In Progress') && (
                            <div
                              className={`${classes.deleteInt} deleteIcon`}
                              onClick={() => {
                                this.setState(
                                  {
                                    modalBroadcastId: broadcast.id,
                                    modalBroadcastTitle: broadcast.name,
                                  },
                                  this.openConfirmModal,
                                )
                              }}>
                              <NewDeleteIcon />
                            </div>
                          )}
                        </div>
                      ))}
                    {data.length !== 0 && (
                      <div
                        style={{
                          textAlign: '-webkit-left',
                        }}>
                        <Pagination
                          page={this.state.page}
                          rowsPerPage={this.state.rowsPerPage}
                          count={data.length}
                          onChangePage={(event, value) => this.handleChangePage(value)}
                        />
                      </div>
                    )}

                    <Modal
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      open={this.state.open}
                      onClose={this.closeConfirmModal}>
                      <ConfirmModal
                        itemId={this.state.modalBroadcastId}
                        itemTitle={this.state.modalBroadcastTitle}
                        closeModal={this.closeConfirmModal}
                        confirmAction={cancelBroadcast}
                      />
                    </Modal>
                  </div>
                </div>
              </div>
            ) : (
              <NoData
                description={description}
                onClick={this.props.handleBroadcastModalOpen}
                buttonTitle={'Add Broadcast'}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

NewBroadcastsList.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  broadcasts: state.broadcasts,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(NewBroadcastsList)),
)
