import React from 'react'
import * as S from './CustomDataChart.style'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import Tooltip from '../../../../../uiKit/TooltipInfo/Tooltip'

interface CustomDataChartProps {
    data: number
    title: string
    loading: boolean
    fractionDigits: number
    showDollarSign?: boolean
    tooltipText?: string
}

export const CustomDataChart: React.FC<CustomDataChartProps> = ({
  data,
  title,
  loading,
  fractionDigits,
  showDollarSign = false,
  tooltipText,
}) => {

  const prepareData = () => {
    return `${showDollarSign ? '$' : ''}${data.toLocaleString('en-US',
      {minimumFractionDigits: fractionDigits})}`
  }

  const renderContent = () => {
    if (loading) {
      return <LoaderSmall showLoader={true}/>
    } else {
      return <S.Value>{prepareData()}</S.Value>
    }
  }

  return <S.Container>
    {renderContent()}
    <S.Title>{title}
      {tooltipText && <Tooltip tooltipId={'TooltipInfo-userdata'} tooltipText={tooltipText} />}
    </S.Title>
  </S.Container>
}
