export const styles = () => ({
  attributesContainer: {
    maxHeight: 236,
    overflowY: 'scroll',
    overflowX: 'hidden',
    outline: 'none',
  },
  button: {
    height: 36,
    width: 212,
    color: 'var(--color-button-secondary)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid rgba(53, 64, 82, 0.1)',
    cursor: 'pointer',
    background: 'var(--color-white)',
    fontSize: 14,
  },
  addAttibute: {
    padding: '8px 12px',
    backgroundColor: 'var(--color-hover-overlay-primary)',
    cursor: 'pointer',
  },
  optionTitle: {
    color: 'rgba(53, 64, 82, 0.7)',
    fontSize: 14,
    margin: 0,
  },
  option: {
    margin: '4px 0px 0px',
    color: 'var(--color-white)',
    padding: '1px 8px',
    borderRadius: 50,
    width: 'fit-content',
    fontSize: 14,
    cursor: 'pointer',
    backgroundColor: 'var(--color-button-secondary)',
  },
  container: {
    zIndex: 10000,
    width: 200,
    background: 'var(--color-white)',
    position: 'absolute',
    border: '1px solid rgba(53, 64, 82, 0.1)',
    borderRadius: 5,
    overflow: 'hidden',
  },
})
