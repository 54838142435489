import React, {useContext, useEffect, useMemo, useState} from 'react'
import {DateControlContext} from '../../../../contexts/DateControlContext'
import {CustomDonutChart} from './common/CustomDonutChart'
import {getStrainTypesDonutChatData} from '../../api/customCharts'
import {PieChartDTO} from '../../model/customChartModels'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

interface StrainTypesDonutChartProps {
  botId: number
  strainTypesChartData?: PieChartDTO[]
}

const StrainTypesDonutChart: React.FC<StrainTypesDonutChartProps> = ({botId, strainTypesChartData}) => {

  const [loading, setLoading] = useState(false)
  const {startDate, endDate} = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    getStrainTypesDonutChatData(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  const data = useMemo(() => strainTypesChartData && strainTypesChartData.map(cd => {
    return [cd.type, cd.count]
  }), [strainTypesChartData])

  return <CustomDonutChart
    title={'Strain Type'}
    loading={loading}
    fileName={'strain_type'}
    chartData={data}
  />
}

const mapStateToProps = (state) => ({
  strainTypesChartData: state.strainTypesChartData,
  botId: state.activeBot?.id,
})

export default withRouter(connect(mapStateToProps)(StrainTypesDonutChart))
