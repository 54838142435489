import React, {useState} from 'react'

export const SortDirectionImage: React.FC = () => {
  const [isRotated, setRotated] = useState(false)

  const toggleRotation = () => {
    setRotated(!isRotated)
  }

  return <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    onClick={toggleRotation}
    style={{
      transform: `rotate(${isRotated ? 180 : 0}deg)`,
      transition: 'transform 0.3s ease-in-out'
    }}
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      /* eslint-disable-next-line max-len */
      d="M3.52851 5.52851C3.78886 5.26816 4.21097 5.26816 4.47132 5.52851L7.99992 9.05711L11.5285 5.52851C11.7889 5.26816 12.211 5.26816 12.4713 5.52851C12.7317 5.78886 12.7317 6.21097 12.4713 6.47132L8.47132 10.4713C8.21097 10.7317 7.78886 10.7317 7.52851 10.4713L3.52851 6.47132C3.26816 6.21097 3.26816 5.78886 3.52851 5.52851Z"
      fill="#11113A"
    />
  </svg>
}
