import React, {useMemo} from 'react'
import {areaChartTooltipText} from '../../../dashboard/constants/chartsTexts'
import AreaChartButton from '../../../dashboard/components/AreaChartButton'
import AreaChart from '../../../dashboard/components/AreaChart'
import ChartContainer from '../../../../uiKit/ChartContainer'
import {usersChartDataType} from '../../../../models/DashboardTypes'
import { isObjectEmpty } from '../../../../helpers/isObjectEmpty'

interface ActiveUsersChartProps {
    botId: number
    usersStatistics?: usersChartDataType
}

export const ActiveUsersChart: React.FC<ActiveUsersChartProps> = ({botId, usersStatistics}) => {

  const platformsNumber = useMemo(() => {
    if (usersStatistics?.points && !isObjectEmpty(usersStatistics?.points)) {
      return Object.values(usersStatistics?.points)[0]?.map(({ platform }) => platform)?.length > 2
    }
  }, [usersStatistics?.points])

  const activeUsersNumber = useMemo(() => {
    if (usersStatistics?.usersBetweenDates && !isObjectEmpty(usersStatistics?.usersBetweenDates)) {
      return Object.values(usersStatistics?.usersBetweenDates).reduce((acc, count) => acc + count, 0)
    }
    return 0
  }, [usersStatistics?.usersBetweenDates])

  return (
    <ChartContainer
      title="Active users"
      tooltipText={areaChartTooltipText}
      newUsers={usersStatistics?.newUsers}
      activeUsers={activeUsersNumber}
      button={usersStatistics && <AreaChartButton />}>
      <AreaChart isMultipleChannels={!!platformsNumber} botId={botId} />
    </ChartContainer>
  )
}
