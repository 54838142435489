import React from 'react'
import DownloadButton from '../../../../uiKit/buttons/DownloadButton'
import {BrandDTO} from '../../model/customChartModels'

interface DownloadTopBrandsButtonProps {
  chartData: BrandDTO[]
}

export const DownloadTopBrandsButton: React.FC<DownloadTopBrandsButtonProps> = ({chartData}) => {

  const prepareDataToDownload = (): any[][] => {
    return chartData.map(d => {
      return [d.brand, d.clicks]
    })
  }

  return <DownloadButton
    csvData={chartData}
    csvFilename={'top_5_brands'}
    csvHeaders={['Brand', 'Clicks']}
    csvOnDownload={() => prepareDataToDownload()}
  />
}
