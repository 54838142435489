import React from 'react'
import { Popover, withStyles } from '@material-ui/core'
import { HOME_PATH } from '../../../configs'
import { NavLink, withRouter } from 'react-router-dom'
import { ThreeIcon } from '../../../uiKit/icons/Icons'
import { connect } from 'react-redux'
import ShareModal from './ShareModal'
import { hasPermissionForBot } from '../../../security'
import CloneModal from './NewBotModal'
import Modal from '../../../uiKit/Modal'
import { theme } from 'constants/theme'

const styles = theme => ({
  '@global': {
    '.class*=[MuiListItem-button]:hover': {
      background: 'none',
    },
    '.rootElement > * ': {
      border: '1px solid',
      borderColor: theme.tabs.home.botPlate.menuBorder,
      boxShadow: '0px 1px 5px rgba(19, 69, 186, 0.241904)',
      borderRadius: 10,
    },
    '.icon:hover path': {
      stroke: 'rgb(58, 63, 98)',
    },
  },
  popover: {
    width: 145,
    height: 62,
  },
  button: {
    fontSize: 14,
    color: theme.tabs.home.botPlate.menuButtonText,
    display: 'flex',
    alignItems: 'center',
    padding: '11px 24px',
    height: '50%',
    '&:hover': {
      background: 'var(--color-hover-overlay-primary)',
    },
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '40px',
  },
  bot: {
    width: '250px',
    maxWidth: '250px',
    boxSizing: 'border-box',
    height: '170px',
    flex: 'auto',
    margin: '10px',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    color: 'var(--color-text-primary) !important',
    backgroundColor: theme.tabs.home.botPlate.botBackground,
    borderRadius: 10,
    fontSize: '16px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: 'bold',
    display: 'flex !important',
    justifyContent: 'flex-start',
    padding: '24px !important',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

class BotPlate extends React.Component {
  closeModal = () => {
    this.setState({
      openModal: false,
      openModalClone: false,
    })
  }

  toggle = value => {
    this.setState({
      open: value,
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      totalUsers: 0,
      newUserToday: 0,
      open: false,
      openModal: false,
      openModalClone: false,
    }
  }

  render() {
    const { classes, bot } = this.props
    return (
      <div className={classes.bot}>
        <NavLink
          to={HOME_PATH + '/bot/' + bot.id + '/analytics'}
          onClick={() => this.props.switchBot(bot)}>
          <div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 180 }}>
                <p
                  style={{
                    fontSize: 16,
                    color: theme.tabs.home.botPlate.botText,
                  }}>
                  {bot.name}
                </p>
              </div>
            </div>
          </div>
        </NavLink>
        {hasPermissionForBot(bot.id, 'shareBot') && (
          <div
            className="icon"
            style={{ cursor: 'pointer', height: 30 }}
            ref={node => {
              this.anchorEl = node
            }}
            onClick={() => {
              this.toggle(true)
            }}>
            <ThreeIcon />
          </div>
        )}
        <Popover
          open={this.state.open}
          anchorEl={this.anchorEl}
          className="rootElement"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => {
            this.toggle(false)
          }}>
          <div className={classes.popover}>
            <div
              onClick={() => {
                this.setState({
                  openModal: true,
                  open: false,
                })
              }}
              className={classes.button}>
              Share
            </div>
            <div
              onClick={() => {
                this.setState({
                  openModalClone: true,
                  open: false,
                })
              }}
              className={classes.button}>
              Clone
            </div>
          </div>
        </Popover>

        <Modal
          open={this.state.openModal}
          tabIndex="-1"
          onClose={this.closeModal}
          title={'Share chatbot'}>
          <ShareModal bot={bot} closeModal={this.closeModal} />
        </Modal>

        <Modal
          open={this.state.openModalClone}
          tabIndex="-1"
          onClose={this.closeModal}
          title={'Clone chatbot'}>
          <CloneModal
            buttonName="Clone"
            botId={bot.id}
            botName={`Copy of ${bot.name}`}
            onClose={this.closeModal}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  params: state.attributes,
})
export default withRouter(
  withStyles(styles, { withTheme: true })(connect(mapStateToProps)(BotPlate)),
)
